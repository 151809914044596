





















import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import SearchBarComponent from '@/components/shared/SearchBar.component.vue';
import ToggleButtonGroupComponent from '@/components/shared/ToggleButtonGroup.component.vue';
import {Permission} from '@/misc/enums/permission.enum';
import {RequestParams} from '@/interfaces/Responses';
import Location from '@/models/Location';

const CustomerStore = namespace('customer');

@Component({
  computed: {
    Permission() {
      return Permission;
    },
  },
  components: {
    ToggleButtonGroupComponent,
    SearchBarComponent,
    LocationTableComponent: () => import(
      /* webpackChunkName: "UserTableComponent" */
      '@/components/location/LocationTable.component.vue'),
    LocationManageMasterDataComponent: () => import(
      '@/components/location/LocationManageMasterData.component.vue'),
  },
})
export default class LocationsOverviewView extends Vue {

  @CustomerStore.Getter('locations')
  private _locations!: Location[];
  @CustomerStore.Action('loadLocationsAction')
  private loadLocationsAction!: (payload: RequestParams<Location>) => Promise<Location[]>;
  private selectedFilter: string = 'active';
  private searchString: string = '';

  /**
   * Filters the Users according to the value in the searchbar and the statusfilter
   */
  get filteredLocations(): Location[] {
    let filteredUsers = this.locations;

    // filters for status
    if (this.selectedFilter !== 'all') {
      if (this.selectedFilter === 'active') {
        filteredUsers = filteredUsers.filter((user) => user.active);
      } else {
        filteredUsers = filteredUsers.filter((user) => !user.active);
      }
    }
    return filteredUsers;
  }

  get locations(): Location[] {
    return this._locations;
  }

  private async mounted() {
    try {
      await this.loadLocationsAction({companyId: this.$route.params.companyId, populate: ['customer']});
    } catch (e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
  }
}
